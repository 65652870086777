<!-- 雷达库 -->
<template>
  <div>
    <br />
    <label>导入特殊线索手机号，每行一个</label><br /><br />
    <textarea class="area" v-model="remarks"></textarea><br />
    <el-button type="primary" @click="myinput">确 定</el-button>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { input_tsmobile } from "@/api/login.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: false,

      remarks: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //沟通备注
    myinput(e) {
      console.log("导入");
      // this.loading = true;

      console.log(this.remarks);
      input_tsmobile({
        insert_data: this.remarks,
      }).then((res) => {
        console.log(res);
        if (res.code === 0) {
          console.log("导入成功");
          this.$message.success("成功导入【" + res.data.count + "】条!");
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  // margin-top: 80px;
  min-height: 80vh;
  @include padding;
  min-width: 1100px;
  padding-bottom: 0;
}
.info_wrap {
  width: 800px;
  // height: 80px;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
  margin: auto;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}

img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto 0px;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
  margin: 10px 0px auto 0px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 230px;

  /* background-color: red; */
}
// .selfAppraisal {
//   /* 多行超出隐藏 */
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   overflow: hidden;
//   height: 57px;
//   height: 48px\0;
// }
/* 期望 */
.expect {
  float: left;

  width: 400px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.radar-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.radar-btn + i {
  position: absolute;
  bottom: 20px;
  right: 10px;
}
.radar-comment {
  margin-left: 7px;
  float: left;
}
.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.area {
  border: 1px solid #ff9900;
  width: 450px;
  height: 300px;
  min-width: 450px;
  max-width: 450px;
  font-size: 16px;
  color: $selfColor;
  // text-align: center;
  // margin: 0 auto;
  display: inline-block;
  font-family: auto;
}
.area:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.chat {
  width: 30px;
  height: 30px;
  float: left;
}

.chat_div {
  float: right;
  margin-right: -60px;
  margin-top: 90px;
  line-height: 30px;
}
.iconfont {
  font-size: 28px;
}
</style>
